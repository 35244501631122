// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fort Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import '~toastr/toastr';

/*
@import "~daterangepicker/daterangepicker.css";

@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

@import '~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css';

@import '~select2/dist/css/select2.min.css';

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
*/